<template>
    <SetupGlobal @pressed="finished" buttonText="LET'S GET STARTED">
        <Popup
            v-if="popupBoolean"
            :skipIntroScreen="true"
            @close="popupBoolean = false"
            @done="finished"
            introImage="https://ss3.nyc3.digitaloceanspaces.com/bh-dev/no-instance/FF_a5f3d5ec3e.png"
            color="purple"
            :step="{}"
            v-model="step"
            title="Headline goes here"
            text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec ac erat gravida, tempor magna quis, lacinia ex. Pellentesque ac ex aliquam dui feugiat consequat. Morbi id dolor enim. Mauris dictum ligula"
        >
            <template v-slot:right>
                <component
                    :is="'Step' + currentStep"
                    @next="step++"
                    @done="finished"
                    @load="(is) => (loadPopup = is ? is : !loadPopup)"
                />
            </template>
        </Popup>
    </SetupGlobal>
</template>

<script>
import SetupGlobal from "bh-mod/components/layout/SetupGlobal";
import Popup from "bh-mod/components/layout/SetupGlobal/Popup";
import BhLoading from "bh-mod/components/common/Loading";
export default {
    components: { SetupGlobal, Popup, BhLoading },
    data() {
        return {
            loadPopup: false,
            popupBoolean: false,
            currentStep: 0,
            step: 0,
            steps: [],
        };
    },
    watch: {
        step(val) {
            if (this.steps[val]) {
                this.currentStep = val;
            } else {
                this.finished();
            }
        },
    },
    methods: {
        finished() {
			this.$store.commit("LOAD", true);
            this.$store.commit("GET_STARTED");
            this.$router.push("/");
            let user = this.$store.state.user.user;
            let accountInfo = {
                name: user.firstName + " " + user.lastName,
                firstName: user.firstName,
                lastName: user.lastName,
                avatar: user.avatar || "",
                jobTitle: "",
                email: user.email,
                company: user.company || "",
                phone: { value: user.phone || "", type: "Work" },
                website: "",
                template: "1",
                socials: [],
                officePhone: "",
                address: "",
                fax: "",
            };
            this.$api.post(`/settings/:instance/features/user`, {
                options: { seenIntro: true, account: accountInfo },
            }).finally(() => {
				this.$store.commit("LOAD", false);
			});
        },
        getStarted() {
            this.popupBoolean = true;
        },
    },
};
</script>

<style lang="scss">
.amenity-dialog {
    [role="tablist"] {
        display: none;
    }
}
</style>
